import { CustomRouteObject } from './types'
import { lazyLoad } from './utils'

// ----------- Pages -----------
// Lists
const PlansList = lazyLoad(() => import(/* webpackChunkName: 'plans-list' */ '~/pages/PlansList'))
const InvoicesList = lazyLoad(
  () => import(/* webpackChunkName: 'invoices-list' */ '~/pages/InvoicesList'),
)

// Creation
const CreatePlan = lazyLoad(
  () => import(/* webpackChunkName: 'create-plan' */ '~/pages/CreatePlan'),
)
const CreateInvoice = lazyLoad(
  () => import(/* webpackChunkName: 'create-plan' */ '~/pages/CreateInvoice'),
)
const CreateSubscription = lazyLoad(
  () => import(/* webpackChunkName: 'create-subscription' */ '~/pages/CreateSubscription'),
)
const WalletForm = lazyLoad(
  () => import(/* webpackChunkName: 'wallet-form' */ '~/pages/WalletForm'),
)

// Details
const SubscriptionDetails = lazyLoad(
  () => import(/* webpackChunkName: 'subscription-details' */ '~/pages/SubscriptionDetails'),
)
const PlanDetails = lazyLoad(
  () => import(/* webpackChunkName: 'plan-details' */ '~/pages/PlanDetails'),
)

// ----------- Routes -----------
// Lists
export const PLANS_ROUTE = '/plans'
export const INVOICES_ROUTE = '/invoices'
export const INVOICES_TAB_ROUTE = '/invoices/:tab'

// Creation

export const CREATE_PLAN_ROUTE = '/create/plans'
export const UPDATE_PLAN_ROUTE = '/update/plan/:planId'


export const CREATE_INVOICE_ROUTE = '/customer/:customerId/create-invoice'

export const CREATE_SUBSCRIPTION = '/customer/:customerId/create/subscription'
export const UPDATE_SUBSCRIPTION = '/customer/:customerId/update/subscription/:subscriptionId'
export const UPGRADE_DOWNGRADE_SUBSCRIPTION =
  '/customer/:customerId/upgrade-downgrade/subscription/:subscriptionId'

export const CREATE_WALLET_ROUTE = '/customer/:customerId/wallet/create'
export const EDIT_WALLET_ROUTE = '/customer/:customerId/wallet/:walletId'

// Details
export const CUSTOMER_SUBSCRIPTION_DETAILS_ROUTE =
  '/customer/:customerId/subscription/:subscriptionId/:tab'
export const PLAN_SUBSCRIPTION_DETAILS_ROUTE = '/plan/:planId/subscription/:subscriptionId/:tab'
export const PLAN_DETAILS_ROUTE = '/plan/:planId/:tab'
export const CUSTOMER_SUBSCRIPTION_PLAN_DETAILS =
  '/customer/:customerId/subscription/:subscriptionId/plan/:planId/:tab'

export const PLAN_TEMPLATE_DETAIL_ROUTE = '/plan-template/:planId/:tab'

export const objectListRoutes: CustomRouteObject[] = [
  {
    path: [PLANS_ROUTE, '/'],
    private: true,
    element: <PlansList />,
  },
  {
    path: [INVOICES_ROUTE, INVOICES_TAB_ROUTE],
    private: true,
    element: <InvoicesList />,
  },
]

export const objectCreationRoutes: CustomRouteObject[] = [
  {
    path: [CREATE_PLAN_ROUTE, UPDATE_PLAN_ROUTE],
    private: true,
    element: <CreatePlan />,
  },
  {
    path: [CREATE_INVOICE_ROUTE],
    private: true,
    element: <CreateInvoice />,
  },
  {
    path: [CREATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION, UPGRADE_DOWNGRADE_SUBSCRIPTION],
    private: true,
    element: <CreateSubscription />,
  },
  {
    path: [CREATE_WALLET_ROUTE, EDIT_WALLET_ROUTE],
    private: true,
    element: <WalletForm />,
  },
]

export const objectDetailsRoutes: CustomRouteObject[] = [
  {
    path: [CUSTOMER_SUBSCRIPTION_DETAILS_ROUTE, PLAN_SUBSCRIPTION_DETAILS_ROUTE],
    private: true,
    element: <SubscriptionDetails />,
  },
  {
    path: [PLAN_DETAILS_ROUTE, CUSTOMER_SUBSCRIPTION_PLAN_DETAILS, PLAN_TEMPLATE_DETAIL_ROUTE],
    private: true,
    element: <PlanDetails />,
  },
]
